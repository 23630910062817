import NewPort from '@/layouts/Stack/components/NewPort';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';

export default {
  mixins: [showErrorModal, storeMixin],
  data() {
    return {
      device_id: '',
      data: {},
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    newPort: function (instance, device) {
      // console.log(instance, device);
      if (device) this.device_id = device;
      const that = this;
      return new Promise(() => {
        const selfName = 'newPort';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewPort,
          closeOnBackdrop: false,
          props: {
            net: instance.id,
            instance: instance,
            shared: this.isShared ? this.isShared : false,
            device: device,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              // console.log(instance);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              // console.log('notready');
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
            ready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  if (device) {
                    this.addNewPort(instance)
                      .then(async data => {
                        this.addNewPortToServer(data.port.id)
                          .then(async data => {
                            // console.log(data);
                            this.newFetch();
                            that.$modals.close();
                            this.showResModal('Порт успешно создан.');
                          })
                          .catch(e => {
                            // console.log(e);
                            that.$modals.close();
                            this.showError(e);
                          });
                      })
                      .catch(e => {
                        that.$modals.close();
                        this.showError(e);
                      });
                  } else {
                    this.addNewPort(instance)
                      .then(async () => {
                        this.newFetch();
                        that.$modals.close();
                        this.showResModal('Порт успешно создан.');
                      })
                      .catch(e => {
                        that.$modals.close();
                        this.showError(e);
                      });
                  }
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    addNewPort(payload) {
      return this.$store.dispatch('moduleStack/createPort', payload);
    },
    addNewPortToServer(payload) {
      const params = {
        payload: payload,
        device_id: this.device_id,
      };
      return this.$store.dispatch('moduleStack/createPortToServer', params).catch(e => {
        // console.log(e);
        this.showError(e);
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        confirm: {
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
